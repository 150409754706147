@import '../Mewsstrap/variables.less';
@import './mews-colors.less';

/*
 * Contains common fixes for various UI problems that are caused by code migrations
 * Each rule in this file should be well documented. Do include reason for rule and when can be removed
 * Upon removal, do NOT forget to go throw all of the code and clear old and unused classes
 */

/* TODO This file should be ideally empty */

/* boostrap .hidden has !important, remove when migration to Material Admin replaces boostrap version 3  */
.no-show {
    display: none;
}

/* bootstrap is missing ellipsis class out of the box, possibly integrate this to typography */
.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Material Admin is missing image placeholder listview tags, possibly integrate this into custom listview styles */
.lv-img(@size, @width) {
    &.lv-img-@{size} {
        position: relative;
        height: @width;
        width: @width;
        .placeholder {
            background: #757575;
            display: block;
            color: white;
            text-align: center;
            line-height: @width;
            font-size: @width / 2;
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 50%;

            .mews-logo::before {
                content: 'mews_logo_small';
            }
        }
        & > img {
            border-radius: 50%;
        }
    }
}

.lv-img(sm, 35px);
.lv-img(md, 45px);
.lv-img(lg, 75px);

/* Tooltips show too much data for inherit font-size, remove once UI is reworked in way tooltips show sane amounts of data */
@media screen {
    .tooltip {
        max-width: 200px;
        padding: 4px;
        pointer-events: none;
        text-align: center;
        text-transform: none;
    }
}

/* Fix mobile form group aligning, to prevent stuff touching edges of screen, remove when forms migrate to material design */
.form-horizontal {
    .form-group {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}

.clickable {
    cursor: pointer;
}

/* Search-on-page action mark element */
mark {
    padding: 0 !important;
    background: @mews-color-neutral-300 !important;
    display: inline-block !important;
    overflow: initial !important;

    &.highlight {
        background: @mews-color-primary-500 !important;
        color: white !important;
    }
}

/* material 8 rythm */
.p-8 {
    padding: 8px;
}
.p-16 {
    padding: 16px;
}

.progress-bar {
    position: relative;

    .progress-bar-value {
        &.outer {
            position: absolute;
            transform: translate(100%, 0);
            color: @text-color;
            right: -8px;
            white-space: nowrap;
        }
    }
}
.mews-fields-group {
    & > div:not(:last-of-type) {
        margin-bottom: 16px;
    }
}

.mews-button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    & > *:not(:last-child) {
        margin-right: 8px;
    }
}

.panel,
.card {
    border: 1.5px solid hsl(240, 21%, 90%);
    border-radius: 8px !important;
}

.new-card.mews-toolbar {
    border-left: 1.5px solid hsl(240, 21%, 90%);
}

.card.mews-toolbar {
    border-color: rgb(239, 239, 245);
}

.panel-heading {
    border-radius: 8px !important;
}

label {
    margin-bottom: 0 !important;
}

.mews-menu-view {
    margin: -10px;
    display: flex;
    flex-direction: column;

    @media (min-width: 900px) {
        flex-direction: row;
        min-height: calc(~'100% + 20px');
    }

    .view {
        flex: 1 1;
        display: flex;
        justify-content: center;

        @media (min-width: 900px) {
            justify-content: flex-start;
        }
    }
}

@settings-max-width: 640px;
.mews-settings-view-container {
    width: 100%;
    padding: 0px 16px;
    padding-top: 32px;
    max-width: @settings-max-width;
    margin-left: 0;

    @media (min-width: 900px) {
        max-width: @settings-max-width;
    }

    @media (min-width: 992px) {
        margin-left: 80px;
    }
}

.mews-view-container {
    max-width: @settings-max-width;
    margin: 0 auto;
}

.mews-card-container {
    .card;
    .p-16;
    .mews-view-container;
}

.input-group .input-group-addon {
    background-color: transparent;
    border-bottom: 1px solid #ccc;
}

.mews-customer-profile {
    & > .mews-view-container {
        max-width: initial;
    }
}

.btn-group {
    display: inline-flex;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: -4px;
    margin-right: -8px;

    & > .btn,
    & > div {
        display: inline-flex;
        margin-bottom: 4px;
        margin-right: 8px;
    }
}

// ul, ol margin is missing in css reset (originally part of type.less from bootstrap)
ul,
ol {
    margin-top: 0;
    margin-bottom: 8px;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

// Description Lists
dl {
    margin-top: 0; // Remove browser default
    margin-bottom: @line-height-computed;
}
dt,
dd {
    line-height: @line-height-base;
}
dt {
    font-weight: 700;
}
dd {
    margin-left: 0; // Undo browser default
}

.dl-horizontal {
    dd {
        &:extend(.clearfix all); // Clear the floated `dt` if an empty `dd` is present
    }

    @media (min-width: @dl-horizontal-breakpoint) {
        dt {
            float: left;
            width: (@dl-horizontal-offset - 20);
            clear: left;
            text-align: right;
            .text-overflow();
        }
        dd {
            margin-left: @dl-horizontal-offset;
        }
    }
}

a.btn {
    text-decoration: none;
    display: inline-flex;

    &:hover:active {
        display: inline-flex;
    }

    &.btn-default,
    &.btn-info {
        &:hover,
        &:focus,
        &.focus {
            color: @mews-color-text-primary;
            text-decoration: none;
        }
    }

    &.btn-primary {
        &:hover,
        &:focus,
        &.focus {
            color: @mews-color-white;
            text-decoration: none;
        }
    }

    span[data-test-icon] {
        display: block;
    }
}

#ss_survey_widget {
    margin: 0 auto;
}

.mews-expand,
.mews-collapse,
.btn.minus,
.btn.plus {
    > [data-reactcomponent] {
        margin: 0 !important;
        line-height: 1;
    }
}
