.mews-loader {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.7);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2000;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
