@import '../Variables/mews-variables.less';

#timeline {
    overflow: hidden;
    line-height: 0;
    position: relative;
}

dl.both-sides dt {
    font-weight: 600;
    color: @mews-color-neutral-400;
    float: left;
    clear: both;
}

dl.both-sides dd {
    text-align: right;
    margin-bottom: 10px;
    min-width: 100px;
}

#tooltip {
    white-space: pre-wrap;
    line-height: normal;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 0.4s;
    opacity: 1;
    transform: scale(1);
}

.tooltip {
    position: absolute;
    z-index: 89;
}
