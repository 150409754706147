@small-padding: 8px;
@medium-padding: @small-padding * 2;
@big-padding: @small-padding * 3;

.card-content {
    padding-left: @medium-padding;
    padding-right: @medium-padding;
    padding-top: @small-padding;
}

.card-primary-actions {
    padding: @small-padding;
    text-align: right;
    a {
        margin-right: @small-padding;
    }

    .pull-left {
        text-align: left;
        a {
            margin-left: @small-padding;
            margin-right: 0;
        }
    }
}

.card,
.new-card {
    .modal-header {
        border-radius: 4px 4px 0px;
        padding: 5px 0px 0px 0px !important;
    }

    table {
        margin-bottom: 0px;
    }
}
