* {
    .box-sizing(border-box);
}
*:before,
*:after {
    .box-sizing(border-box);
}

html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    font-size: @font-size-base;
    line-height: @line-height-base;
    color: @text-color;
    background-color: @body-bg;
}

a {
    color: @link-color;
    text-decoration: none;

    &:hover,
    &:focus {
        color: @link-hover-color;
        text-decoration: @link-hover-decoration;
    }

    &:focus {
        .tab-focus();
    }
}

hr {
    margin-top: @line-height-computed;
    margin-bottom: @line-height-computed;
    border: 0;
    border-top: 1px solid @hr-border;
}

img {
    vertical-align: middle;
}

.img-responsive {
    .img-responsive();
}

.clearfix {
    .clearfix();
}

.pull-right {
    float: right !important;
}

.pull-left {
    float: left !important;
}

.hidden {
    display: none !important;
}

.hidden-print {
    @media print {
        .responsive-invisibility();
    }
}

.visible-print-inline {
    display: none !important;

    @media print {
        display: inline !important;
    }
}

.visible-print {
    .responsive-invisibility();

    @media print {
        .responsive-visibility();
    }
}

.hidden-xs {
    @media (max-width: @screen-xs-max) {
        .responsive-invisibility();
    }
}
