.alert {
    padding-left: 30px;
    font-size: 13px;

    &:not(.alert-dismissible) {
        padding-right: 30px;
    }

    &.alert-dismissable {
        padding-right: 44px;
    }
}
