.mews-sign-in-image {
    height: 47vh;
    min-height: 200px;
    background-clip: border-box;
    background-size: cover;
    background-position: center;
    margin-right: (@grid-gutter-width / -2);
    margin-left: (@grid-gutter-width / -2);
    text-align: center;
}
