textarea:hover,
input:hover,
select:hover,
textarea:active,
input:active,
select:active,
textarea:focus,
input:focus,
select:focus,
button:focus,
button:active,
button:hover {
    &.form-control {
        outline: 0px !important;
        .box-shadow(none);
        border: 1px solid #ccc;
    }
}

input.form-control,
select.form-control,
textarea.form-control {
    text-shadow: 0px 0px 1px lighten(@mews-color-font, 70%) !important;
    border: none !important;
    border-bottom: 1px solid #ccc !important;
    box-shadow: none !important;
    padding: 0px;

    &:hover,
    &:active {
        border: none !important;
        border-bottom: 1px solid #ccc !important;
    }

    &:focus {
        border: none !important;
        border-bottom: 1px solid @mews-color-primary-500 !important;
    }
}

.form-group {
    text-align: left;
    max-width: 100%;

    &.form-group-btn {
        text-align: right;
    }

    .mews-select-field {
        margin-bottom: -15px; // compensate react SelectField own margin
    }
}

.form-group.error {
    .control-label {
        color: @mews-color-warning-error;
    }

    .form-control {
        border: 1px solid @mews-color-warning-error !important;
    }
}

.alert {
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
}

@media (min-width: @screen-sm-min) {
    .form-horizontal {
        .form-group {
            label.control-label {
                padding-right: 10px;
                width: 25%;
                float: left;
            }

            .mews-control-container {
                float: left;
                width: 75%;
                padding-right: 10%;
            }

            .clearfix;
        }
    }
}

.input-group,
input[type='checkbox'],
input[type='radio'] {
    min-width: 15px;
}

.error {
    input,
    textarea,
    select {
        border: 1px solid @mews-color-warning-error !important;
    }

    .mews-react-field {
        input,
        textarea,
        select {
            border: none !important;
        }

        div[data-field] {
            border: 1px solid @mews-color-warning-error !important;
        }
    }

    .input-group-addon {
        background-color: @mews-color-danger-500;
        color: white;
    }
}

.mews-payment-provider {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 16px;

    span {
        font-weight: 600;
        margin-right: 8px;
    }
}

.form-control {
    display: block;
    width: 100%;
    height: @input-height-base;
}

label {
    display: inline-block;
    max-width: 100%;
}

textarea.form-control {
    height: auto;
}

.form-group {
    margin-bottom: @form-group-margin-bottom;
}

.input-group {
    display: table;
}

.input-group-addon,
.input-group .form-control {
    display: table-cell;
}

.input-group-addon {
    width: 1%;
}

.input-group-addon {
    padding: @padding-base-vertical @padding-base-horizontal;
    font-size: @font-size-base;
    font-weight: 400;
    line-height: 1;
    color: @input-color;
    text-align: center;
}
