tr.row-clickable {
    cursor: pointer;

    &:hover {
        background-color: #ededef;
    }
}

.mews-total-row td,
.mews-total-row th,
td.mews-total-cell,
th.mews-total-cell {
    font-weight: 600;
}

table.table > thead > tr > th,
table.table-bordered > thead > tr > th {
    border-bottom-width: 1px;
}

tr.mews-alternate-row,
td.mews-alternate-cell,
th.mews-alternate-cell {
    background-color: @mews-color-neutral-25;
}

.table-responsive-wrapper {
    overflow-x: auto;
    position: relative;

    @media print {
        overflow-x: initial;
    }
}

.table-responsive {
    position: relative;

    thead {
        th {
            font-size: 12px;
            font-weight: 600;
        }
    }

    & > thead.sticky {
        position: absolute;
        top: 0px;
        background: white;
        z-index: 9;

        th {
            border-top: 0;
        }

        @media print {
            display: none;
        }
    }

    & > tbody > tr > td {
        .label {
            vertical-align: initial;
            margin: 0 2px;
        }
    }

    & > tbody > tr:last-child > th,
    td {
        // compensate for MacOS collapsed scroll-bar height
        padding-bottom: 10px !important;
    }
}

@media screen and (max-width: 767px) {
    table.table-responsive {
        border: 0;

        & input[type='text'] {
            min-width: 100px;
        }
    }
}
