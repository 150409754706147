@media print {
    .hidden-print,
    form,
    .form-inline,
    .control-group {
        display: none !important;
    }

    .invisible-print {
        color: white !important;
    }

    a[href]:after {
        content: none;
    }

    thead {
        display: table-header-group;
    }

    tr,
    td,
    th,
    tbody,
    thead,
    tfoot {
        page-break-inside: avoid !important;
    }

    .table > thead > tr > th,
    .table > thead > tr > td,
    .table > tbody > tr > th,
    .table > tbody > tr > td,
    .table > tfoot > tr > th,
    .table > tfoot > tr > td,
    .table th,
    .table td {
        border-width: 0.2pt;
        border-color: #000;
    }

    h2 {
        font-size: 10px;
        font-weight: 600;
        margin-top: 0;
    }

    h3 {
        page-break-after: auto;
    }

    .logo img {
        max-width: 100%;
        max-height: 70px;
        width: 80px;
        margin: 1em;
    }

    table.pdf-simple-table {
        tr td,
        tr th {
            border: 0px;
        }
    }
}
