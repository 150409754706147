@import '../../Variables/colors-variables.less';

.report-description {
    color: @mews-color-link-dark;
    font-size: medium;
    padding: 10px;
    text-align: center;
    font-weight: 600;

    &.report-not-complete {
        color: @mews-color-warning-error !important;
    }
}
