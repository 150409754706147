@ease-out: cubic-bezier(0, 0, 0.2, 1);
@ease-in: cubic-bezier(0.4, 0, 1, 1);
@move: cubic-bezier(0.4, 0, 0.2, 1);

@transition-enter-time: 350ms;
@transition-exit-time: 270ms;

.fade(@direction, @transform) {
    &.fade-@{direction}-enter {
        opacity: 0.01;
        transform: @transform;
    }

    .fade-@{direction}-enter.fade-@{direction}-enter-active {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: all @transition-enter-time @ease-out;
    }

    .fade-@{direction}-exit {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    .fade-@{direction}-exit.fade-@{direction}-exit-active {
        opacity: 0.01;
        transform: @transform;
        transition: all @transition-exit-time @ease-out;
    }
}

.fade(up, translate3d(0, 100%, 0));
.fade(down, translate3d(0, -100%, 0));
.fade(in, translate3d(0, 10%, 0));
