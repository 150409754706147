.media {
    .clearfix();

    & > .pull-left {
        padding-right: 15px;
    }

    & > .pull-right {
        padding-left: 15px;
    }

    .media-body {
        overflow: hidden;
    }

    overflow: visible;
    margin-top: 15px;
}

.media-body {
    zoom: 1;
    display: block;
    width: auto;
}

.dl-horizontal dt {
    text-align: left;
}
