@media (min-width: @screen-sm-min) {
    .mews-toolbar {
        .btn,
        .btn-group a {
            margin-top: (@line-height-computed);
        }

        .form-inline {
            .form-group {
                text-align: left;
                vertical-align: top;

                .control-label {
                    margin-right: 10px;
                }
            }
            select {
                max-width: 150px;
            }

            .mews-react-field.mews-select-field {
                @media (min-width: @screen-sm-min) {
                    width: 200px;
                }
            }

            margin-bottom: 10px;
        }
    }
}
