// used in messaging and settings
// uses bootstrap mixins

.panel {
    margin-bottom: @line-height-computed;
    background-color: @panel-bg;
    border: 1px solid transparent;
    border-radius: @panel-border-radius;
    .box-shadow(0 1px 1px rgba(0, 0, 0, 0.05));
}

.new-panel {
    margin-bottom: @line-height-computed;
    background-color: @panel-bg;
}

// Panel contents
.panel-body,
.new-panel-body {
    padding: @panel-body-padding;
    &:extend(.clearfix all);
}

// Optional heading
.panel-heading {
    padding: @panel-heading-padding;
    border-bottom: 1px solid transparent;
    .border-top-radius((@panel-border-radius - 1));
}

.new-panel.heading {
    padding: @panel-heading-padding;
}

// Within heading, strip any `h*` tag of its default margins for spacing.
.panel-title,
.new-panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: ceil((@font-size-base * 1.125));
    color: inherit;
}

.panel-group {
    margin-bottom: @line-height-computed;

    // Tighten up margin so it's only between panels
    .panel {
        margin-bottom: 0;
        border-radius: @panel-border-radius;

        + .panel {
            margin-top: 5px;
        }
    }

    .panel-heading {
        border-bottom: 0;

        + .panel-collapse > .panel-body,
        + .panel-collapse > .list-group {
            border-top: 1px solid @panel-inner-border;
        }
    }
}

.new-panel-group {
    margin-bottom: @line-height-computed;

    // Tighten up margin so it's only between panels
    .new-panel {
        margin-bottom: 0;

        + .new-panel {
            margin-top: 5px;
        }
    }
}

.panel-default {
    .panel-variant(@panel-default-border; @panel-default-text; @panel-default-heading-bg; @panel-default-border);
}
.panel-danger {
    .panel-variant(@panel-danger-border; @panel-danger-text; @panel-danger-heading-bg; @panel-danger-border);
}
