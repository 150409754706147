@mews-color-black-info: @mews-color-neutral-400;
@mews-color-warning-error: @mews-color-warning;

@mews-color-font: @mews-color-text-primary;

/* ============================================ */
/* ==============    aliases    =============== */
/* ============================================ */
@mews-color-link-default: @mews-color-font;
@mews-color-link-dark: @mews-color-black-info;
