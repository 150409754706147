@import './MaterialAdminLegacy/bootstrap-overrides.less';
@import './MaterialAdminLegacy/generics.less';
@import './Material/mews-ui.less';
@import './Mewsstrap/mewsstrap.less';
@import './Mewsstrap/variables.less';
@import './Variables/mews-variables.less';

@import './Components/forms.less';
@import './Components/toolbar.less';
@import './Components/layouts.less';
@import './Components/typography.less';
@import './Components/reports.less';
@import './Components/loader.less';
@import './Components/modals.less';
@import './Components/tables.less';
@import './Components/labels.less';

@import './Modules/sign-in.less';
@import './Modules/timeline.less';
@import './Modules/Reports/index.less';

@import './print.less';

@media screen {
    html,
    body {
        // Safari/IE prevent scroll bars
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        // IOS preventing horizontal scrolling
        width: 100%;
        position: fixed;

        // fixes stuttered scrolling on ios
        -webkit-overflow-scrolling: touch;
    }

    body {
        font-weight: 500;
        display: flex;
        flex-direction: column;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }

    h4 {
        font-weight: 600;
    }
}

.no-padding() {
    padding: 0;
}

.right {
    text-align: right;
}

.center {
    text-align: center;
}

.mews-cursor-link,
a {
    cursor: pointer;
}

textarea {
    resize: vertical;
}

.mews-no-wrap {
    white-space: nowrap;
}

.dl-horizontal {
    margin: 0;

    dt {
        padding: 3px 3px 3px 0px;
        text-align: left;
    }

    dd {
        margin-left: auto;
        padding: 3px 0;
        word-break: break-word;
    }

    @media screen and (min-width: 1024px) {
        dt {
            width: 30%;
        }
    }
}

.has-error .form-control {
    border: 1px solid @mews-color-warning-error !important;
}

.btn:not(.btn-xs):not(.btn-link) {
    i {
        font-size: 16px;
    }
}

.btn-primary {
    background-color: @mews-color-neutral-500;

    &.mews-btn-action-needed {
        background-color: @mews-info-complementary;
        border-color: @mews-info-complementary;

        &:hover {
            background-color: darken(@mews-info-complementary, 10%);
            border-color: darken(@mews-info-complementary, 10%);
        }

        &:active,
        &:focus {
            background-color: darken(@mews-info-complementary, 20%);
            border-color: darken(@mews-info-complementary, 20%);
        }
    }
}

.day-header {
    position: relative;
    padding: 4px;
    font-size: 12px;
    display: inline-block;
    height: 100%;

    .day-events {
        position: absolute;
        top: 2px;
        right: 4px;
        color: white;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        background: @mews-color-text-primary;
        text-align: center;
        font-weight: normal;
    }

    .day-name {
        display: block;
    }

    .day-date {
        display: block;
        font-weight: normal;
    }
}

.weekend {
    background: @mews-color-neutral-25;
}

.today {
    background: @mews-color-primary-50;
}
