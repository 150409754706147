@import './mews-colors.less';
@import './transitions.less';

.mews-icons {
    font-family: 'Mews Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    vertical-align: middle;
    text-align: center;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';

    // Centering small icon

    &.align-text-bottom {
        vertical-align: text-bottom;
    }

    // Rules for sizing the icon.
    &.md-16 {
        font-size: 16px;
    }
    &.md-24 {
        font-size: 24px;
    }
    &.md-36 {
        font-size: 36px;
    }

    &.flip {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: 'FlipH';
    }

    // icons
    &.facebook::before {
        content: 'facebook';
    }
    &.linkedin::before {
        content: 'linkedin';
    }
    &.twitter::before {
        content: 'twitter';
    }
}

body {
    &.mews-icons-loaded {
        & .mews-icons {
            opacity: 1;
        }
    }
    & .mews-icons {
        opacity: 0;
        transition: opacity @ease-in @transition-exit-time;
    }
}
