@import '../../MaterialAdminLegacy/alert.less';
@import '../mews-colors';

@fullscreen-width: 768px;

.alert-holder-wrapper {
    position: fixed;
    // Only temporary till replacing by kanban-4673
    z-index: 999999;
    @media (max-width: @fullscreen-width) {
        width: 100%;
    }

    &.position-top {
        top: 110px;
    }

    &.position-bottom {
        bottom: 0px;
    }

    &.position-left {
        left: 0px;
    }

    &.position-right {
        right: 0px;
    }

    &.position-center {
        left: 50%;
        transform: translate3d(-50%, 0, 0);
    }

    .alert {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 540px;
        padding: 0px;
        margin: 10px 20px;
        text-align: inherit;
        font-size: 14px;
        font-weight: 600;
        border-radius: 4px;
        box-shadow: @shadow-surface-3;

        @media (max-width: @fullscreen-width) {
            width: 100%;
            margin: 10px 0px 0px 0px;
        }

        .message {
            word-wrap: break-word;
            overflow-wrap: break-word;
            word-break: break-word;
            white-space: pre-line;
            padding: 15px;
        }

        .dismiss {
            color: #fff;
            padding-right: 15px;
        }

        &-info {
            color: @mews-color-white;
            background-color: @mews-inverse-complementary;
            border-color: @mews-inverse-complementary;
        }

        &-success {
            color: @mews-color-white;
            background-color: @mews-success-complementary;
            border-color: @mews-success-complementary;
        }

        &-warning {
            color: @mews-color-neutral-1000;
            background-color: @mews-warning-complementary;
            border-color: @mews-warning-complementary;

            & button {
                color: @mews-color-neutral-1000;
            }
        }

        &-danger {
            color: @mews-color-white;
            background-color: @mews-danger-complementary;
            border-color: @mews-danger-complementary;
        }
    }
}
