body {
    color: @mews-color-link-default;

    a {
        color: @mews-color-link-default;

        &:hover {
            text-decoration: none !important;
            color: lighten(@mews-color-link-default, 30%);
        }
    }
}

h3 {
    font-size: 1.4em;
}

.text-larger {
    font-size: 1.3em;
}

// Alignment
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.text-center {
    text-align: center;
}
