@import '../mews-colors';

.chip {
    @media not print {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: @mews-color-text-primary;
        text-align: center;
        margin-left: 8px;

        i {
            font-size: 16px;
        }
    }
}
