@import '../../Material/mews-colors';
@import '../../Material/transitions';

@toolbar-width-mobile: 100%;
@toolbar-width-desktop: 350px;

.report {
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
    overflow: hidden;

    & > .mews-toolbar,
    .report-content,
    .new-report-content {
        overflow: auto;
        height: 100%;
    }

    @media print {
        .card,
        .new-card,
        .report-content,
        .new-report-content {
            padding: 0px !important;
            box-shadow: none !important;
        }

        .report,
        .report-content,
        .new-report-content {
            display: block;
            width: 100%;
            height: auto;
            overflow: initial;
        }

        .mews-toolbar {
            display: none;
        }
    }

    .mews-toolbar {
        width: @toolbar-width-desktop;
        border-radius: 0px !important;
        flex: 0 0 auto;
        margin-right: 0px;
        border-top: none;
        border-bottom: none;
        border-right: none;
        transition: margin @transition-enter-time @move;

        @media only screen and (max-width: 899px) {
            width: @toolbar-width-mobile;
        }

        form {
            display: flex;
            flex-direction: column;
            height: 100%;

            .filter-form {
                flex: 1 1 auto;
                overflow: auto;
                padding: 16px;

                .form-group {
                    margin-bottom: 8px;
                }
            }
        }

        .form-group-btn {
            padding: 16px;
            margin: 0px;
            width: 100%;
            background: @mews-color-white;
            border-top: 1px solid #eee;

            .btn {
                margin-top: 0px;
            }
        }
    }

    .report-content,
    .new-report-content {
        flex-grow: 1;
        flex-shrink: 1;
    }

    .new-report-content {
        padding: 24px;

        & > .new-card {
            margin-bottom: 0;
            padding: 0;
        }

        @media only screen and (max-width: 899px) {
            transition: margin @transition-enter-time @move;

            padding: 8px;
        }
    }

    .report-content {
        padding: 16px;

        & > .card {
            margin-bottom: 0px;
            padding: 16px;
        }

        @media only screen and (max-width: 899px) {
            transition: margin @transition-enter-time @move;

            padding: 8px;
            & > .card {
                padding: 8px;
            }
        }
    }
}

.without-filter .report {
    .mews-toolbar {
        margin-right: -@toolbar-width-desktop;
        box-shadow: 0 0px 0px rgba(51, 51, 51, 0.38);

        @media only screen and (max-width: 899px) {
            margin-right: -@toolbar-width-mobile;
        }
    }

    @media (max-width: 899px) {
        .report-content,
        .new-report-content {
            margin-left: 0px;
        }
    }
}
