@import '../../Variables/colors-variables.less';

table.hotel-cleaning {
    text-align: left;
    thead,
    tfoot {
        display: table-row-group;
    }

    tr.border-top td,
    tr.border-top th {
        border-top: thin solid;
        border-color: darkgray !important;
    }

    tr > td,
    tr > th {
        padding: 2px !important;
    }

    td.room-use-reservation,
    td.room-use-house-use {
        background-color: @mews-color-neutral-50;
    }

    td.room-use-ooo-block {
        background-color: @mews-color-neutral-50;
    }

    td.now-cell-left,
    th.now-cell-left {
        border-right: 1px dashed black;
    }

    td.now-cell-right,
    th.now-cell-right {
        border-left: 1px dashed black;
    }

    td.no-border-left,
    th.no-border-left {
        border-right: 0;
    }

    td.no-border-right,
    th.no-border-right {
        border-left: 0;
    }

    td.action-menu button {
        padding: 0;
        width: 25px;
        height: 25px;
    }

    td.should-be-cleaned {
        text-align: right;

        i {
            color: @mews-color-black-info;
        }
    }

    td .use-container {
        display: flex;
        flex-flow: row wrap;

        .description {
            order: 2;
            flex: 3 auto;
        }

        .date {
            order: 1;
            flex: 1 auto;
        }

        .time {
            order: 3;
            flex: 1 auto;
        }

        .notes {
            order: 4;
            flex: 1 100%;

            p {
                margin: 10px 0px 0px 0px;
                text-align: center;
            }
        }

        @media (max-width: 800px) {
            .description {
                order: 3;
                flex: 1 100%;
                margin-top: 10px;
            }

            .date {
                order: 1;
                flex: 1 50%;
            }

            .time {
                order: 2;
                flex: 1 50%;
            }
        }

        @media print {
            display: block;

            .description,
            .date,
            .time {
                display: inline;
                margin: 0px;
            }
        }
    }

    @media print {
        &.table-bordered th,
        &.table-bordered td {
            border-color: black !important;
        }

        &.table-bordered th.no-border-left,
        &.table-bordered td.no-border-left {
            border-right-color: transparent !important;
        }

        &.table-bordered th.no-border-right,
        &.table-bordered td.no-border-right {
            border-left-color: transparent !important;
        }
    }
}
