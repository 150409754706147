.modal-body-padding {
    padding: 4px;

    @media screen and (min-width: 768px) {
        padding: 8px;
    }
}

.modal-body {
    background: hsl(0, 0%, 100%);
}

.height-fixed {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    @media print {
        overflow: auto;
        height: auto;
    }
}
