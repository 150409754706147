@import '../../Variables/colors-variables.less';

@companionHeight: 23px;
@maxNotesHeight: 53px;
@minCardWidth: 384px;

.cardSize(@cardCount) {
    @media screen and (min-width: (@minCardWidth * @cardCount)) {
        width: calc((floor(100% / @cardCount)) ~'- 16px');
        & when (@cardCount = 6) {
            width: calc((floor(100% / @cardCount)) ~'- 1px');
        }
    }
}

.reservation-overview {
    .size {
        width: 100%;
        display: flex;
        margin: 8px;

        .cardSize(2);
        .cardSize(3);
        .cardSize(4);
        .cardSize(5);
        .cardSize(6);

        @media print {
            display: inline-block;
            width: 50%;

            .card,
            .new-card {
                .card-primary-actions {
                    display: none;
                }
                .companion-list {
                    height: auto;
                    max-height: none !important;
                }
            }
        }

        .card,
        .new-card {
            width: 100%;
            display: flex;
            flex-flow: column;
            margin-bottom: 0;

            .content-wrap {
                flex-grow: 1;

                .companion-list {
                    overflow: auto;
                    max-height: 3.4 * @companionHeight + @maxNotesHeight;
                    margin: 0px;
                    padding-left: 20px;

                    .heading-badge {
                        margin-left: 4px;
                    }
                }
                .notes {
                    padding-top: 8px;
                    max-height: @maxNotesHeight;
                    font-size: 11px;
                    overflow: hidden;
                    word-break: break-word;
                }
            }
            .short-hand {
                font-size: 12px;

                & > span,
                a {
                    display: inline-block;
                    &::after {
                        content: '/';
                        display: inline-block;
                        padding: 0 4px;
                    }

                    &:last-child {
                        &:after {
                            content: '';
                            padding: 0px;
                        }
                    }
                }
            }
        }
    }
}
