.report-description {
    color: @mews-color-font;
    font-size: medium;
    padding: (@grid-gutter-width / 2);
    text-align: center;
    font-weight: 600;
}

.mews-report-cell-notes {
    width: 20%;
}
